import Icon from '@elements/Icon';
import Button from '@elements/buttons/Button';
import InputField from '@elements/forms/InputField';
import { handleValidation } from '@helpers/handleValidation';
import { yupResolver } from '@hookform/resolvers/yup';
import { updatePassword } from '@providers/profile';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { validationSchema } from './validation';

export default function PasswordReset({ isOnboarding = false }) {
  const [isValid, setIsValid] = useState({});

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    trigger,
    reset,
    watch,
  } = useForm({ resolver: yupResolver(validationSchema), mode: 'onChange' });

  function handleValidationWrapper(e, error) {
    handleValidation(e, error, trigger, setIsValid);
  }

  async function onSubmit(data) {
    const response = await updatePassword(data.oldPassword, data.password);
    if (response && response.status === 200) {
      toast.success('Je wachtwoord is aangepast.', {
        position: 'bottom-right',
        icon: <Icon iconName="check" color={'text-green-500'} />,
        autoClose: 3000,
        closeButton: false,
        bodyClassName: 'font-primary text-green-500',
      });
      reset();
      setIsValid({});
    } else if (response && response.status === 400) {
      toast.error('Oude wachtwoord is ongeldig', {
        position: 'bottom-right',
        autoClose: 3000,
        icon: <Icon iconName="exclamation" color="text-functional-error" />,
        closeButton: false,
        bodyClassName: 'font-primary text-functional-error',
      });
    } else {
      toast.error('Wachtwoord niet bijgewerkt', {
        position: 'bottom-right',
        autoClose: 3000,
        icon: <Icon iconName="exclamation" color="text-functional-error" />,
        closeButton: false,
        bodyClassName: 'font-primary text-functional-error',
      });
    }
  }

  useEffect(() => {
    function checkValidity(fields) {
      trigger(fields).then((valid) => {
        const updatedIsValid = { ...isValid };
        for (const field of fields) {
          updatedIsValid[field] = valid;
        }
        setIsValid(updatedIsValid);
      });
    }

    const subscription = watch((value, { name }) => {
      switch (name) {
        case 'oldPassword':
          if (value.password && value.passwordConfirmation) {
            checkValidity(['password', 'passwordConfirmation']);
          } else if (value.password) {
            checkValidity(['password']);
          } else if (value.passwordConfirmation) {
            checkValidity(['passwordConfirmation']);
          }
          return;
        case 'password':
          if (value.oldPassword && value.passwordConfirmation) {
            checkValidity(['oldPassword', 'passwordConfirmation']);
          } else if (value.oldPassword) {
            checkValidity(['oldPassword']);
          } else if (value.passwordConfirmation) {
            checkValidity(['passwordConfirmation']);
          }
          return;
        case 'passwordConfirmation':
          if (value.password && value.oldPassword) {
            checkValidity(['password', 'oldPassword']);
          } else if (value.password) {
            checkValidity(['password']);
          } else if (value.oldPassword) {
            checkValidity(['oldPassword']);
          }
          return;
      }
    });

    return () => subscription.unsubscribe();
  }, [isValid]);

  return (
    <form className="container flex flex-col pb-10 font-primary" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col">
        {!isOnboarding && <h2 className="font-primary text-3xl">Wachtwoord wijzigen</h2>}
        <p className="mb-8 mt-1">Wijzig hier je wachtwoord.</p>
      </div>
      <div className="rounded-md bg-white p-8 shadow-lg">
        <div className="flex w-full flex-col gap-8">
          <InputField
            label="Oude Wachtwoord*"
            name="oldPassword"
            type="password"
            register={register}
            errors={errors}
            message={errors.oldPassword?.message}
            onKeyUp={(e) => handleValidationWrapper(e, errors.oldPassword)}
            isValid={isValid['oldPassword']}
          />
          <InputField
            label="Nieuwe Wachtwoord*"
            name="password"
            type="password"
            register={register}
            errors={errors}
            message={errors.password?.message}
            onKeyUp={(e) => handleValidationWrapper(e, errors.password)}
            isValid={isValid['password']}
          />
          <InputField
            id="passwordConfirmation"
            label="Herhaal wachtwoord*"
            name="passwordConfirmation"
            type="password"
            disabled={!isValid['password']}
            register={register}
            errors={errors}
            message={errors.passwordConfirmation?.message}
            onKeyUp={(e) => handleValidationWrapper(e, errors.passwordConfirmation)}
            isValid={isValid['passwordConfirmation']}
          />
        </div>
        <div className="flex justify-end pt-4">
          <Button
            buttonType="primary"
            disabled={
              !isDirty ||
              !isValid['password'] ||
              !isValid['passwordConfirmation'] ||
              !isValid['oldPassword']
            }
            type="submit"
            label="Verzenden"
            overrideClass="!max-w-full"
          />
        </div>
      </div>
      <ToastContainer theme="light" />
    </form>
  );
}
