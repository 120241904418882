import { userStore } from '@context/userStore';
import DetailList from '@elements/DetailList';
import Modal from '@elements/Modal';
import Button from '@elements/buttons/Button';
import { classNames } from '@helpers/classNames';
import { getCategories } from '@providers/category';
import { getProfileData } from '@providers/profile';
import PasswordReset from 'feature/profile/fragment/PasswordReset';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { useEffect, useState } from 'react';
import PersonalDetailsModalForm from './PersonalDetailsModalForm';

const phoneUtil = PhoneNumberUtil.getInstance();

const occupations = {
  label: 'Beroep*',
  choices: [
    { value: '- Selecteren -' },
    { value: 'Aannemer' },
    { value: 'Adviseur' },
    { value: 'Architect' },
    { value: 'Bouwkundige' },
    { value: 'Docent' },
    { value: 'EP-adviseur' },
    { value: 'Facilitair manager' },
    { value: 'Gebouwbeheerder' },
    { value: 'Inkoper' },
    { value: 'Installateur (E)' },
    { value: 'Installateur (S)' },
    { value: 'Installateur (W)' },
    { value: 'Monteur' },
    { value: 'Projectmanager/Kwaliteitsborger' },
    { value: 'Projectontwikkelaar' },
    { value: 'Student' },
    { value: 'Technisch tekenaar' },
    { value: 'Vastgoedprofessional' },
    { value: 'Werkvoorbereider' },
    { value: 'Overig' },
  ],
};

export default function PersonalDetailsForm({ isOnboarding }) {
  const { profileId } = userStore();

  const [workfields, setWorkfields] = useState({
    label: 'Werkveld*',
    choices: [{ value: '- Selecteren -' }],
  });

  const [showModal, setShowModal] = useState(false);
  const [profile, setProfileData] = useState(null);
  const [occupation, setOccupation] = useState(occupations.choices[0].value);
  const [workfield, setWorkfield] = useState(workfields.choices[0].value);
  const [phoneValue, setPhoneValue] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch categories
        let response;
        response = await getCategories();
        let categoryChoices = [];
        if (response && response.status === 200) {
          categoryChoices = [
            { value: '- Selecteren -' },
            ...response.data.map((item) => ({ value: item.name })),
          ];
        }

        setWorkfields((prevWorkfields) => ({
          ...prevWorkfields,
          choices: categoryChoices,
        }));

        // Fetch user data
        response = await getProfileData();

        if (response && response.status === 200) {
          setProfileData(response.data);

          // Match and set occupation
          let matchingOccupation = null;
          for (const choice of occupations.choices) {
            if (choice.value === response.data.occupation) {
              matchingOccupation = choice;
              break;
            }
          }
          setOccupation(matchingOccupation?.value || occupations.choices[0].value);

          // Match and set work field
          let matchingWorkfield = null;
          for (const choice of categoryChoices) {
            if (choice.value === response.data.work_field) {
              matchingWorkfield = choice;
              break;
            }
          }
          setWorkfield(matchingWorkfield?.value || '- Selecteren -');

          //Set phone field
          setPhoneValue(response.data.phone_number);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [profileId]);

  let formattedProfileNumber;

  function formatToNational(phoneNum, countryCode) {
    const parsedNumber = phoneUtil.parse(phoneNum, countryCode);
    return phoneUtil.format(parsedNumber, PhoneNumberFormat.NATIONAL);
  }

  if (profile && profile.phone_number) {
    const databaseNumberValue = profile.phone_number;
    formattedProfileNumber = formatToNational(databaseNumberValue, 'NL');
  } else {
    formattedProfileNumber = phoneValue;
  }

  const userDetails = [
    { label: 'E-mail', value: profile?.email },
    { label: 'Voornaam', value: profile?.firstname },
    { label: 'Tussenvoegsel', value: profile?.lastname_prefix },
    { label: 'Achternaam', value: profile?.lastname },
    { label: 'Telefoonnummer', value: formattedProfileNumber },
    { label: 'Beroep', value: profile?.occupation },
    { label: 'Werkveld', value: profile?.work_field },
  ];

  const onClickModal = () => {
    setShowModal(!showModal);
  };

  const modalFormProps = {
    occupations,
    occupation,
    setOccupation,
    workfield,
    setWorkfield,
    workfields,
    profile,
    getProfileData,
    setProfileData,
    setShowModal,
    phoneValue,
    setPhoneValue,
  };

  return (
    <div className="container mx-auto pb-10 font-primary">
      <div className="flex justify-between">
        <div className="flex flex-col">
          {!isOnboarding && <h2 className="font-primary text-3xl">Persoonlijke gegevens</h2>}
          <p className={classNames('mb-8 mt-1 ', isOnboarding && 'font-light')}>
            Bekijk hier je profielgegevens.
          </p>
        </div>

        <div className="mt-1">
          <Button
            type="button"
            icon="pencil"
            buttonType="secondary"
            onClick={() => onClickModal()}
          />
        </div>
      </div>

      {profile ? <DetailList details={userDetails} /> : <p>Loading profile data...</p>}
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        modalTitle="Werk mijn details bij"
        modalContent={<PersonalDetailsModalForm {...modalFormProps} />}
      />
      <div className="pt-10">
        <PasswordReset />
      </div>
    </div>
  );
}
